import { QuestionData } from "./interfaces";
import { getQuestionsArr } from '../ts/questionBuilders';

function formatLangName(str: string) {
  // Remove Hyphens
  str = str.replace('-', ' ');

  // Capitalize all first letters
  let words = str.split(' ');
  words.forEach((word, i) => {
    if ((word !== "language") && (word !== "the")) {
      words[i] = word[0].toUpperCase() + word.slice(1);
    }
  });

  return words.join(' ');
}

function formatDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

export const runGameTester = async () => {
  let url = new URL(window.location.href);
  let params = Object.fromEntries(Array(...url.searchParams.entries()));
  if (params.difficulty && params.category) {
    let difficulty: string = "easy";
    let questionsCategory: string = "random";
    let numGames: number = 50;
    let questionsArr: Array<QuestionData> = [];
    console.log("Building ", numGames, " test games with ", difficulty, " difficulty and ", questionsCategory, " category");

    if (params.difficulty) {
      difficulty = params.difficulty;
    }
    if (params.category) {
      questionsCategory = params.category;
    }
    if (params.games) {
      numGames = parseInt(params.games);
    }
    
    const dateTime = formatDateTime();
    const baseFileName = `games_${difficulty}_${questionsCategory}_${dateTime}`;
    
    var csvContent: string = ' ,question 1, answer set 1, correct answer 1, question 2, answer set 2, correct answer 2, question 3, answer set 3, correct answer 3, question 4, answer set 4, correct answer 4, question 5, answer set 5, correct answer 5, question 6, answer set 6, correct answer 6, question 7, answer set 7, correct answer 7, question 8, answer set 8, correct answer 8, question 9, answer set 9, correct answer 9, question 10, answer set 10, correct answer 10\n';
    if (params.showcat) {
      csvContent = ' ,question 1, question 1 type, answer set 1, correct answer 1, question 2, question 2 type, answer set 2, correct answer 2, question 3, question 3 type, answer set 3, correct answer 3, question 4, question 4 type, answer set 4, correct answer 4, question 5, question 5 type, answer set 5, correct answer 5, question 6, question 6 type, answer set 6, correct answer 6, question 7, question 7 type, answer set 7, correct answer 7, question 8, question 8 type, answer set 8, correct answer 8, question 9, question 9 type, answer set 9, correct answer 9, question 10, question 10 type,  answer set 10, correct answer 10\n';
    } 

    // Create a second file with question type counts
    var questionTypes: Set<string> = new Set();
    var typeCountContent: string = 'Game';

    var allGamesQuestionsArr: Array<QuestionData[]> = [];
    for (let i = 1; i <= numGames; i++) {
      questionsArr = await getQuestionsArr("", 10, difficulty, questionsCategory, []);
      allGamesQuestionsArr.push(questionsArr);
      questionsArr.forEach(question => questionTypes.add(question.type));
      console.log("built " + i + "/" + numGames + " TEST GAMES");
    }

    questionTypes.forEach(type => {
      typeCountContent += `,${type}`;
    });
    typeCountContent += '\n';

    for (let i = 1; i <= numGames; i++) {
      console.log("building game " + i);
      csvContent += 'game ' + i + ',';
      let typeCounts: { [key: string]: number } = {};
      questionsArr = allGamesQuestionsArr[i - 1];

      questionsArr.forEach(question => {
        if (!typeCounts[question.type]) {
          typeCounts[question.type] = 0;
        }
        typeCounts[question.type]++;
      });
      
      for (let j = 0; j < questionsArr.length; j++) {
        csvContent += '"' + getQuestionText(questionsArr, difficulty, j) + '",';
        if (params.showcat) {
          csvContent += '"' + questionsArr[j]["type"] + '",';
        }
        if (questionsArr[j]["type"] === "date") {
          csvContent += '"';
          for (let k = 0; k < questionsArr[j]["options"].length; k++) {
            csvContent += "'" + questionsArr[j]["options"][k] + "'  ";
          }
          csvContent += '",';
        } else if (questionsArr[j]["type"] === "origin-multi") {
          csvContent += '"';
          for (let k = 0; k < questionsArr[j]["options"].length; k++) {
            csvContent += " '" + questionsArr[j]["options"][k] + "'  /";
          }
          csvContent += '",';
        } else {
          csvContent += '"' + questionsArr[j]["options"] + '",';
        }
        csvContent += '"' + questionsArr[j]["answer"] + '",';
      }
      csvContent += '\n';
      console.log("BUILT " + i + "/" + numGames + " TEST GAMES");

      typeCountContent += `game ${i}`;
      questionTypes.forEach(type => {
        typeCountContent += `,${typeCounts[type] || 0}`;
      });
      typeCountContent += '\n';
    }

    let a = document.createElement('a');
    a.href = 'data:attachment/csv,' +  encodeURIComponent(csvContent);
    a.target = '_blank';
    a.download = `${baseFileName}_gameTester.csv`;
    document.body.appendChild(a);
    a.click();

    let b = document.createElement('a');
    b.href = 'data:attachment/csv,' +  encodeURIComponent(typeCountContent);
    b.target = '_blank';
    b.download = `${baseFileName}_gameTypeCounts.csv`;
    document.body.appendChild(b);
    b.click();
  }
}

function getQuestionText(allQuestionData: QuestionData[], difficulty: string, index: number) {
  let { type, category, subject, subjectLangs, range, article, subcat } = allQuestionData[index];
  let currentDifficulty = difficulty;

  if (type === "category-include") {
    // Text for category-include questions
    switch (category) {
      case "brands" :
        return "Choose the word that has been used as a brand name:";
      case "sports" :
        return "Choose the word that has been used as sports terminology:";
      case "money" :
        return "Choose the word that has been used as financial terminology:";
      case "arts" :
        return "Choose the word that has been used as terminology within the arts:";
      case "imitative" :
        return "Choose the word that has been used as an imitation of a sound:";
      case "food" :
        return "Choose the word that has been used in relation to food or drink:";
      case "imported" :
          return "Choose the word that was imported into English:";
      default : 
        return "Invalid Category";
    }
  } else if (type === "category-exclude") {
    // Text for category-exclude questions
    switch (category) {
      case "brands" :
        return "Choose the word that has NOT been used as a brand name:";
      case "sports" :
        return "Choose the word that did NOT originate as sports terminology:";
      case "money" :
        return "Choose the word that did NOT originate as financial terminology:";
      case "arts" :
        return "Choose the word that did NOT originate as terminology within the arts:";
      case "imitative" :
        return "Choose the word that did NOT originate as an imitation of a sound:";
      case "food" :
        return "Choose the word that has NOT been used in relation to food or drink:";
      case "imported" :
          return "Choose the word that was NOT imported into English:";
      default :
        return "Invalid Category";
    }
  } else if (type === "origin-include" && subjectLangs) {
    // Text for origin-include questions
    subjectLangs.forEach((lang, i) => subjectLangs![i] = formatLangName(lang));
    switch (subjectLangs.length) {
      case 1: 
        return "Which word comes from " + subjectLangs[0] + "?";
      case 2:
        return "Which word comes from " + subjectLangs[0] + " and " + subjectLangs[1] + "?";
      default:
        return "Which word comes from languages including " + subjectLangs.slice(0, subjectLangs.length - 1).join(', ') + ", and " + subjectLangs[subjectLangs.length - 1] + "?";
    }
  } else if (type === "origin-exclude" && subjectLangs) {
    // Text for origin-exclude questions
    subjectLangs.forEach((lang, i) => subjectLangs![i] = formatLangName(lang));
    switch (subjectLangs.length) {
      case 1: 
        return "Which word does NOT come from " + subjectLangs[0] + "?";
      case 2:
        return "Which word does NOT come from " + subjectLangs[0] + " and " + subjectLangs[1] + "?";
      default:
        return "Which word does NOT come from languages including " + subjectLangs.slice(0, subjectLangs.length - 1).join(', ') + ", and " + subjectLangs[subjectLangs.length - 1] + "?";
    }
  } else if (type === "origin-multi") {
    // Text for origin-multi questions
    switch (currentDifficulty) {
      case "moderate":
        return "Which two languages does \"" + subject + "\" come from?";
      case "hard":
        return "From which of these languages does modern English get the word \"" + subject + "\"?";
      default:
        return "Which language does \"" + subject + "\" come from?";
    }
  } else if (type === "subcat-include") {
    // Text for subcat-include questions
    if (subcat === "apparel") {
      return "Which of these words has been used in relation to apparel?";
    } else if (subcat === "appearance") {
      return "Which of these words has been used in relation to appearance?";
    } else if (subcat === "currency") {
      return "Which of these words has been used in relation to currency?";
    } else if (subcat === "music") {
      return "Which of these words has been used in music vernacular?";
    } else if (subcat === "dance") {
      return "Which of these words has been used in dance?";
    } else if (subcat === "person") {
      return "Which of these words has been used in relation to a historical figure's name?";
    } else if (subcat === "place") {
      return "Which of these words has been used in relation to a place name?";
    } else if (article) {
      return "Which of these words has been used in relation to " + article.slice(1, -1) + " " +  subcat + "?";
    } else {
      return "Which of these words has been used in " +  subcat + "?";
    }
  } else if (type === "subcat-exclude") {
    // Text for subcat-exclude questions
    if (subcat === "apparel") {
      return "Which of these words has NOT been used in relation to apparel?";
    } else if (subcat === "appearance") {
      return "Which of these words has NOT been used in relation to appearance?";
    } else if (subcat === "currency") {
      return "Which of these words has NOT been used in relation to currency?";
    } else if (subcat === "music") {
      return "Which of these words has NOT been used in music vernacular?";
    } else if (subcat === "dance") {
      return "Which of these words has NOT been used in dance?";
    } else if (subcat === "person") {
      return "Which of these words has NOT been used in relation to a historical figure's name?";
    } else if (subcat === "place") {
      return "Which of these words has NOT been used in relation to a place name?";
    } else if (article) {
      return "Which of these words has NOT been used in relation to " + article.slice(1, -1) + " " +  subcat + "?";
    } else {
      return "Which of these words has NOT been used in " +  subcat + "?";
    }
  } else {
    // Prefix "the" when options will be decades or centuries e.g. "the 1910s" or "the 19th century"
    let the = '';

    if (range?.endsWith('0s') || range?.endsWith('century')) {
      the = 'the ';
    }

    // All other question types
    switch (type) {
      case "date" :
        return "When is the word \"" + subject + "\" first recorded in English?";
      case "date-range-include" :
        return "Which of the following words is first recorded in " + the + range + "?";
      case "date-range-exclude" :
        return "Which of the following words is NOT first recorded in " + the + range + "?";
      case "order" :
        return "Order the following words descending by date of first use (First used word on top):";
      case "first" :
        return "Which of the following words was the first to appear in English?";
      case "last" :
        return "Which of the following words was the last to appear in English?";
      case "subcat-multi" :
        return "The word " + subject + " comes from: ";
    default:
      return "Invalid question type: " + type;
    }
  }
}
